<template>
  <div>
    <h1>Redirection vers Toucan</h1>
    <div v-if="erreur">
      <h3>Une erreur est survenue</h3>
      <p>{{ message }}</p>
    </div>
  </div>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  import toucanService from '@/services/toucanService.js'

  export default {

    data: () => ({

      message: '',
      erreur: false,
    }),

    created() {
      const token = this.$route.query.token
      const returnTo = this.$route.query.returnTo
      const idEvenement = this.$route.query.idEvenement
      this.$store.commit('SET_JWTTOKEN', token)

      restApiService.get('/api/webUser/infoUsager').then(response => {
        toucanService.connexion(response.data, returnTo, idEvenement)
      }).catch((erreur) => {
        this.erreur = true
        this.message = erreur
      })
    },
  }
</script>
